window.serializeForm = (form) => {
    let formData = {}
    form.querySelectorAll("input,select,textarea").forEach((field) => {
        let fieldName = field.getAttribute("name")
        let value = field.value
        formData[fieldName] = value
        if (value === "") {
            field.classList.add("is-invalid")
            field.addEventListener("focus", () => {
                field.classList.remove("is-invalid")
            })
        }
    })
    return formData
}

window.fillForm = (formSelector, fields = {}) => {
    let form = document.querySelector(`${formSelector}`)
    for (let fieldName in fields) {
        let element = form.querySelector(`[name='${fieldName}']`)
        if (element) {
            element.value = fields[fieldName]
        }
    }
}
