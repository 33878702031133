window.addEventListener("load", () => {
    setTimeout(() => {
        document.querySelector('body').classList.add('no-loader')
    }, 1000)
})

const documentReady = (fn) => {
    if (document.readyState === "complete" || document.readyState === "interactive") {
        setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

documentReady(() => {
    "use strict";
    const submenu_animation_speed = 200;

    const sidebar = () => {

        if (document.querySelector(".page-sidebar") && !document.querySelector(".page-sidebar").length) {
            return
        }


        let select_sub_menus = document.querySelector(".accordion-menu li:not(.open) ul"),
            active_page_sub_menu_link = document.querySelector('.accordion-menu li.active-page > a');

        //select_sub_menus.style.display = "none";

        const container = document.querySelector('.page-sidebar');
        //const ps = new PerfectScrollbar(container);
        if (document.querySelector(".accordion-menu li a")) {
            document.querySelector(".accordion-menu li a").addEventListener("click", (e) => {
                let sub_menu = this.next('ul'),
                    parent_list_el = $(this).parent('li'),
                    active_list_element = $('.accordion-menu > li.open'),

                    show_sub_menu = () => {
                        sub_menu.slideDown(submenu_animation_speed);
                        parent_list_el.addClass('open');
                        ps.update();
                    },

                    hide_sub_menu = () => {
                        sub_menu.slideUp(submenu_animation_speed);
                        parent_list_el.removeClass('open');
                        ps.update();
                    },

                    hide_active_menu = () => {
                        document.querySelector('.accordion-menu > li.open > ul').slideUp(submenu_animation_speed);
                        active_list_element.classList.remove('open');
                        ps.update();
                    };

                if (sub_menu.length) {

                    if (!parent_list_el.classList.get('open')) {
                        if (active_list_element.length) {
                            hide_active_menu();
                        }
                        show_sub_menu();
                    } else {
                        hide_sub_menu();
                    }
                    return false;
                }
            })
        }


        if (document.querySelector('.active-page > ul') && document.querySelector('.active-page > ul').length) {
            active_page_sub_menu_link.click();
        }
    };


    const toggleSidebar = () => {
        document.querySelector('body').classList.toggle("sidebar-hidden");
    };

    window.removeSideBar = () => {
        toggleSidebar();
        document.querySelector('#sidebar-toggle').remove()
    }

    if (document.querySelector('#sidebar-toggle')) {
        document.querySelector('#sidebar-toggle').addEventListener("click", () => {
            toggleSidebar();
        })
    }


    const global = () => {
        let forms = document.querySelectorAll('.needs-validation');
        Array.prototype.slice.call(forms)
            .forEach(function (form) {
                form.addEventListener('submit', function (event) {
                    if (!form.checkValidity()) {
                        event.preventDefault();
                        event.stopPropagation();
                    }
                    form.classList.add('was-validated');
                }, false);
            });
    }
    sidebar();
    global();
})



