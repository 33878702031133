window._ = require('lodash');
window.Noty = require('noty');
import TomSelect from "tom-select";

const initializeTomSelects = () => {
    if (document.querySelector("select")) {
        document.querySelectorAll('select').forEach((selectElement) => {
            if (selectElement.dataset.getUrl) { // Remote Data TomSelect Initalization
                new TomSelect(selectElement, {
                    valueField: selectElement.dataset.valueField ?? 'id',
                    labelField: selectElement.dataset.labelField ?? 'name',
                    searchField: selectElement.dataset.searchField ?? 'name',
                    load: function (query, callback) {
                        var url = selectElement.dataset.getUrl
                        fetch(url)
                            .then(response => response.json())
                            .then(json => {
                                callback(json.results);
                            }).catch(() => {
                            callback();
                        });
                    },
                })
            } else {
                new TomSelect(selectElement)
            }
        })
    }
}
window.initializeTomSelects = initializeTomSelects
window.onload = () => {
    //initializeTomSelects();
}


/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */


window.axios = require('axios');

axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    document.querySelectorAll('form').forEach(e => {
        if (e.querySelector('#button-text-container') && e.querySelector('#button-spinner-container')) {
            e.querySelector('#button-text-container').classList.toggle('d-none')
            e.querySelector('#button-spinner-container').classList.toggle('d-none')
            e.querySelector("button[type='submit']").disabled = true;
        }
    })
    return config;
})

window.axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        document.querySelectorAll('form').forEach(e => {
            if (e.querySelector('#button-text-container') && e.querySelector('#button-spinner-container')) {
                e.querySelector('#button-text-container').classList.toggle('d-none')
                e.querySelector('#button-spinner-container').classList.toggle('d-none')
                e.querySelector("button[type='submit']").disabled = false
            }
            Livewire.emit('refresh')
        })

        if (response.data.message) {
            window.notify({
                type: 'success',
                text: response.data.message
            })
        }
        return response
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        document.querySelectorAll('form').forEach(e => {
            if (e.querySelector('#button-text-container') && e.querySelector('#button-spinner-container')) {
                e.querySelector('#button-text-container').classList.toggle('d-none')
                e.querySelector('#button-spinner-container').classList.toggle('d-none')
                e.querySelector("button[type='submit']").disabled = false
            }
        })

        let errors = error.response.data.errors

        for (const error in errors) {
            window.notify({
                type: 'error',
                text: errors[error]
            })
        }
        return error.response
    }
)

window.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
};


// Form Auto Submit
document.querySelectorAll("form").forEach((form) => {
    if (form.hasAttribute("action") && form.hasAttribute("method") && form.hasAttribute("autosubmit")) {

        form.addEventListener("submit", async (event) => {
            event.preventDefault()

            let formData = new FormData(form)
            let url = form.getAttribute("action")
            let method = form.getAttribute("method")

            if (url.includes(":id")) {
                url = url.replace(":id", formData.get("id"))
            }

            let request = {
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'X-BEARER-TOKEN': document.querySelector("meta[name='csrf-token']").getAttribute("content")
                },
                url: url,
                method: method,
                data: formData
            }

            await axios(request).then((response) => {
                if (!(response.data.errors)) {
                    form.closest('.modal').querySelector(".btn-close").click()
                }
            })
        })
    }
})


/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
