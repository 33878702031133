require('./bootstrap');
require('./formSerialize');
require('./main');

// ** Alpine ** //
import Alpine from 'alpinejs';

Alpine.data('demo', () => ({
    open: false,

    toggle() {
        this.open = !this.open
    }
}));
window.Alpine = Alpine;
Alpine.start()
// ** Alpine ** //

window.notify = (params) => {
    let animation = {
        open: 'noty_effects_open', // Animate.css class names
        close: 'noty_effects_close' // Animate.css class names
    }
    params.layout = 'topRight'
    params.animation = animation
    params.timeout = 3000
    new Noty(params).show();
}
import 'bootstrap';


